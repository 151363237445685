import {ChangeEvent, FormEvent, useCallback, useEffect, useState} from 'react';

import Button from '../../common/button/button';
import {ContactFormValues, ValidationRuleContactForm} from './contact-form.types';
import {fetchContactForm, initContactForm, validationRule} from './contact-form.helper';

import stylesCommon from '../../../style/common.module.css';
import styles from './contact-form.module.css';
const ContactForm = () => {
    const [formValues, setFormValues] = useState(initContactForm);
    const [formSent, setFormSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blocking, setBlocking] = useState(false);

    useEffect(()=> {
        if(formSent){
            setFormValues(initContactForm);
            const timer = setTimeout(() => {
                setFormSend(false);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [formSent])

    const fetchDate = useCallback(async (newFormValues : ContactFormValues)=> {
        const result =  await fetchContactForm(newFormValues);
        setFormSend(result);
        setLoading(false);
        setBlocking(false);
    }, [])

    useEffect(()=> {
        if(loading && !blocking){
            const isValid = formValues.note.valid && formValues.name.valid && formValues.email.valid

            if(isValid){
                void fetchDate(formValues);
                setBlocking(true);
            }
            else{
                setLoading(false);
            }
        }
    }, [blocking, fetchDate, formValues, loading])

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const key = event.target.name as keyof ContactFormValues;
        const value = event.target.value;

        setFormValues(prevState => {
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    value
                }
             }
        });
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setFormValues(prevState => {
            let newFormValues = {...formValues};

            for (const key  in newFormValues) {
                const value = newFormValues[key as keyof ContactFormValues].value;
                console.log(key)
                const valid = validationRule[key as keyof ValidationRuleContactForm](value);
                newFormValues[key as keyof ContactFormValues] = {value, valid};
            }
            return newFormValues;
        });
        setLoading(true);
    }

    const validationClass = (valid: boolean) => {
        return `${valid ? '' : ' ' + styles.contact_form__error}`
    }

    return (<div id={'contact'} className={`${stylesCommon.section} ${styles.contact}`}>
        <div>
            <h2 className={`${styles.contact_header} ${stylesCommon.text_header__m} ${stylesCommon.text_header__underline}`}>Contact</h2>
        </div>
        <p className={`${styles.contact_text} ${stylesCommon.text_paragraph__l} `} >Hey buddy, what are you talking about? 😜<br/>Please leave me your contact information. I'll respond as soon as possible. Thank you!</p>
        <form onSubmit={handleSubmit} className={styles.contact_form}>
            {loading && <div className={styles.contact_form__loading} >Plase, wait... </div> }
            <label className={`${stylesCommon.text_paragraph__l} ${styles.contact_form__label}`}>
                <span>const <span className={styles.contact_form__varName}>name</span> =</span>
                <input onChange={handleChange}
                       className={`${styles.contact_form__element}${validationClass(formValues.name.valid)}`}
                       name={'name'}
                       type={'text'}
                       value={formValues.name.value}/>
            </label>
            <label className={`${stylesCommon.text_paragraph__l} ${styles.contact_form__label}`}>
                <span>const <span className={styles.contact_form__varName}>email</span> =</span>
                <input onChange={handleChange}
                       className={`${styles.contact_form__element}${validationClass(formValues.email.valid)}`}
                       name={'email'}
                       type={'text'}
                       value={formValues.email.value}/>
            </label>
            <label className={`${stylesCommon.text_paragraph__l} ${styles.contact_form__label}`}>
                <span>const <span className={styles.contact_form__varName}>note</span> =</span>
                    <textarea onChange={handleChange}
                              className={`${styles.contact_form__element} ${styles.contact_form__textarea}${validationClass(formValues.note.valid)}`}
                              name={'note'}
                              value={formValues.note.value}/>
            </label>
            {formSent ? <p className={`${stylesCommon.text_paragraph__m} ${styles.contact_form__feedback}`}>Thanks for your message! I receive to you soon...</p> : <Button reverse>submit</Button>}
        </form>
    </div>)
}
export default ContactForm;