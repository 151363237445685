import {FC} from 'react';

import {LinkProps} from './link.types';

import styles from './link.module.css';
import stylesCommon from '../../../style/common.module.css'

const Link: FC<LinkProps> = ({text, link, target = '_self', color = 'gradient', size = 'small'}) => {

    const linkUnderlineClasses = `${styles.link_underline} ${color === 'white' ? styles.link_underline__white : styles.link_underline__gradient}`;

    return (
        <a className={styles.link} href={link} target={target}>
            <span className={size === 'small' ? stylesCommon.text_paragraph__s : stylesCommon.text_paragraph__m}>{text}</span>
            <div className={linkUnderlineClasses}></div>
        </a>
    )
}

export default Link