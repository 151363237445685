import {ProjectProps} from "./project/project.types";

export const projects: ProjectProps[] = [
    // {
    //     title: 'Talk Topic',
    //     date: '03.2024',
    //     siteLink: 'https://real-estate-adrianborkowski.pl {ZMIEN}',
    //     codeLink: 'https://gitlab.com/adrianborkowski1991/talk-topic-03.2024 {ZMIEN}',
    //     description: 'Topic Talk is an application designed for individuals to engage in discussions on various topics. Users can log in, create conversation topics, and exchange messages.',
    //     img: '/portfolio/portfolio-3.png {ZMIEN}',
    //     tags: ['Chai', 'Mocha', 'MongoDB', 'Next.js', 'React', 'Socket.IO', 'TypeScript']
    // },
    {
        title: 'Stately Real Estate',
        date: '01.2024',
        siteLink: 'https://real-estate-adrianborkowski.pl',
        codeLink: 'https://gitlab.com/adrianborkowski1991/real-estate-12.2023',
        description: 'The Stately Real Estate application allows for filtering properties. It is a full-stack solution, the main idea of which was to implement the GraphQL query language.',
        img: '/portfolio/portfolio-2.png',
        tags: ['Express', 'GraphQL', 'MongoDB', 'Node', 'React', 'Redux', 'Type Script']
    },
    {
        title: 'Best Burger',
        date: '11.2023',
        siteLink: 'https://best-burger-adrianborkowski.pl',
        codeLink: 'https://gitlab.com/adrianborkowski1991/best-burger',
        description: 'Best Burger is a simple food ordering application. It\'s a full-stack solution based on a React & Node connection, with an additional integrated test payment system using Stripe.',
        img: '/portfolio/portfolio-1.png',
        tags: ['Express', 'MySQL', 'Node', 'React', 'Redux', 'RTL', 'TypeScript', 'Vite']
    },
]