import Link from '../../common/link/link';

import styles from './footer.module.css'
import stylesCommon from '../../../style/common.module.css';
const Footer = () => {
    return <div className={`${stylesCommon.section} ${styles.footer}`}>
        <span>@2023 — Made by Adrian Borkowski</span>
        <div className={styles.footer_buttons}>
            <Link color={'white'} text={'Email'} link={'mailto:info@adrianborkowski.dev'} />
            <Link color={'white'} text={'Gitlab'} link={'https://gitlab.com/adrianborkowski1991'} target={'_blank'}/>
            <Link color={'white'} text={'Linkedin'} link={'https://www.linkedin.com/in/adrian-borkowski-07a1a8a8/'} target={'_blank'}/>
        </div>
    </div>
}

export default Footer;