import Button from '../../common/button/button';

import styles from './header.module.css';
import stylesCommon from '../../../style/common.module.css'

const Header = () => {
    return <header className={`${stylesCommon.section} ${styles.header} `}>
        <a href={'/'} className={`${stylesCommon.text_paragraph__l} ${stylesCommon.gradient}`}>💻
            adrian-borkowski.dev</a>
        <div className={styles.button_container}>
            <div className={styles.button_container__background}>
                <a href='#contact'>
                   <Button>contact me</Button>
                </a>
            </div>

        </div>
    </header>
}

export default Header;