import {FC, useEffect, useRef, useState} from 'react';

import Link from '../../../common/link/link';
import {ProjectProps} from './project.types';
import useScrollHeight from '../../../../hooks/useScrollHeight';

import styles from './project.module.css';
import stylesCommon from '../../../../style/common.module.css';

const Project: FC<ProjectProps> = ({title, date, description, siteLink, codeLink, tags, img}) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const {scrollHeight} = useScrollHeight(-350);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (elementRef.current) {
            if (elementRef.current.offsetTop <= scrollHeight) {
                setIsVisible(true);
            }
        }
    }, [elementRef.current?.offsetTop, scrollHeight]);

    const active = siteLink && codeLink;

    return (
        <div ref={elementRef} className={`${styles.project} ${isVisible ? styles.project_visible : ''}`}>
            {!active && <div className={styles.project_disActive}/>}
            <h1 className={`${styles.project_title} ${stylesCommon.text_header__s}`}>
                <span className={styles.project_var}>{'${'}</span>{title}<span
                className={styles.project_var}>{'}'}</span>
                <span className={styles.project_title__date}>({date})</span></h1>
            <div className={styles.project_description}>
                <p className={stylesCommon.text_paragraph__m}>{description}</p>
                {active && <div className={styles.project_description_buttons}>
                    {siteLink && <Link text={'show site'} link={siteLink} target={'_blank'}/>}
                    {codeLink && <Link text={'show code'} link={codeLink} target={'_blank'}/>}
                </div>}
            </div>
            <div className={styles.project_tags}>
                {tags.map(tag => <span key={tag} className={styles.project_tags__tag}>{tag}</span>)}
            </div>
            <img alt={title} src={img} className={styles.project_img}/>
        </div>
    )
}

export default Project;