
import stylesCommon from '../../../style/common.module.css';
import styles from './hero.module.css';

import Link from '../../common/link/link';

import main from '../../../assets/main.jpg';

const Hero = () => {

    return (<div className={`${stylesCommon.section} ${styles.hero}`}>
        <div className={styles.hero_containerLeft}>
            <h1 className={stylesCommon.text_header__l}>My name is</h1>
            <h1 className={`${stylesCommon.text_header__l} ${stylesCommon.gradient} `}>Adrian Borkowski.</h1>
            <p className={`${stylesCommon.text_paragraph__l} ${styles.hero_containerLeft__text}`}>I'm a JavaScript programmer specializing in developing web applications using React and Typescript technologies. I attach importance to writing clean code.</p>
            <p className={`${stylesCommon.text_paragraph__l} ${styles.hero_containerLeft__text}`}>I'm constantly evolving alongside changing technological trends and collaborating with diverse teams.</p>
            <div className={styles.hero_containerLeft__button}>
                <div>
                    <Link link={'https://gitlab.com/adrianborkowski1991'} target={'_blank'} text={'to portoflio'} size={'large'}/>
                </div>
            </div>
        </div>
        <div className={styles.hero_containerRight}>
            <img src={main} alt={'Hi! My name is Adrian Borkowski. I`m javascript developer'}/>
        </div>
    </div>)
}

export default Hero;