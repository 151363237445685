import {useEffect, useState} from 'react';

import useScrollHeight from '../../../hooks/useScrollHeight';

import styles from './arrow-top.module.css'

const ArrowTop = () => {
    const {scrollY} = useScrollHeight(0);
    const [isVisible, setIsVisible] = useState<boolean | null>(null);

    useEffect(() => {
        const isMoreThanScrollWindow = 100 <= scrollY;

        if(isVisible === null && isMoreThanScrollWindow){
            setIsVisible(100 <= scrollY);
        }
        else if(isVisible !== null){
            setIsVisible(isMoreThanScrollWindow);
        }

    }, [scrollY, isVisible]);

    const handleArrowTopClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>{isVisible !== null && <div className={`${styles.arrow} ${isVisible ? styles.arrow_show : styles.arrow_hidden}`}>
            <div className={styles.arrow_button} onClick={handleArrowTopClick}/>
        </div>}</>
    )
}

export default ArrowTop;