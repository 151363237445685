import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/app/app';

import './style/normalize.module.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App/>);

