import {useCallback, useEffect, useState} from "react";

const useScrollHeight = (addedValue: number) => {
    const [scrollHeight, setScrollHeight] = useState(window.scrollY + window.innerHeight + addedValue);
    const [scrollY, setScrollY] = useState(window.scrollY);

    const handleScroll = useCallback(() => {
        setScrollHeight(window.scrollY + window.innerHeight + addedValue);
        setScrollY(window.scrollY)
    }, [addedValue]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return {scrollHeight, scrollY};
}

export default useScrollHeight;