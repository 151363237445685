import {ContactFormValues, ValidationRuleContactForm} from "./contact-form.types";

export const initContactForm: ContactFormValues = {
    name: {
        value: '',
        valid: true,
    },
    email: {
        value: '',
        valid: true,
    },
    note: {
        value: '',
        valid: true,
    }
}

export const validationRule : ValidationRuleContactForm = {
    name: (value: string) => { return value !== '' },
    email: (value: string) => { return value.includes('@') },
    note: (value: string) => { return value !== '' },
}

export const fetchContactForm = async (contactForm: ContactFormValues) => {
    const url = 'https://formspree.io/f/xoqozrze';

    const formData = new FormData();
    formData.append('name', contactForm.name.value);
    formData.append('email', contactForm.email.value);
    formData.append('note', contactForm.note.value);

    const options = {
        method: 'POST',
        body: formData
    };

    try {
        await fetch(url, options);
    } catch (error) {}
    return true;
}