import Project from './project/project';
import {projects} from './my-work.helper';

import stylesCommon from '../../../style/common.module.css';
import styles from '../my-work/my-work.module.css';
const MyWork = () => {

    return (<div id={'my-work'} className={`${stylesCommon.section} ${styles.myWork}`}>
        <h1 className={`${stylesCommon.text_header__m} ${stylesCommon.text_header__underline}`}>My latest work</h1>
        <div className={styles.myWork_project}>
            {projects.map(project =>
                <Project
                    key={project.title}
                    title={project.title}
                    date={project.date}
                    siteLink={project.siteLink}
                    codeLink={project.codeLink}
                    description={project.description}
                    img={project.img}
                    tags={project.tags}/>)}
        </div>
    </div>)
}

export default MyWork;