import ContactForm from '../sections/contact-form/contact-form';
import Header from '../sections/header/header';
import Hero from '../sections/hero/hero';
import MyWork from '../sections/my-work/my-work';
import Footer from '../sections/footer/footer';

import styles from './app.module.css';
import ArrowTop from '../common/arrow-top/arrow-top';

function App() {

    return (
        <div className={styles.page}>
            <Header/>
            <main>
                <Hero/>
                <MyWork/>
                <ContactForm/>
            </main>
            <Footer/>
            <ArrowTop/>
        </div>
    );
}

export default App;
