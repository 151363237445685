import {FC} from 'react';

import {ButtonTypes} from './button.types';

import styles from './button.module.css';
const Button : FC<ButtonTypes> = ({children, handleClick, reverse = false}) => {

    const buttonClass = `${styles.button} ${reverse ? styles.button_reverse : styles.button_main}`;

    return (
        <button className={buttonClass} onClick={handleClick}>
            {children}
        </button>
    )
}

export default Button;